@import '../../../styles/globalVars.scss';

.wrapperListBook {
  background-color: $colorBgContent;
  border-radius: $radiusRegular $radiusRegular 0 0;
  height: 400px;
  padding: 40px 30px 0;
}

.wrapperBooks {
  border-radius: $radiusRegular $radiusRegular 0 0;
  padding: $paddingRegular 0 $paddingSmall;
  background-color: $colorBgContent;

  .headerBooks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .listBooksText {
    @include RegularBold;
    color: $colorTextPrimary;
    white-space: nowrap;
    padding: 0 !important;
  }

  .headerBooksTitle {
    @include H2;
    color: $colorText;
  }

  .headerBooksSum {
    margin-left: 3px;
  }

  .listBooks {
    display: flex;
    overflow-y: scroll;
    margin: 0 -25px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    .book {
      margin: 25px 0 0 28px;

      .image {
        width: 111px;
        min-width: 111px;
        height: 174px;
        min-height: 174px;
        background-color: $colorBgLight;
        border-radius: 4px;
      }
    }
  }

  .listBooks::-webkit-scrollbar {
    width: 0;
  }

  .listBooksOpen {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 0;
  }

  .listEmpty {
    color: $colorTextLight;
  }
}
