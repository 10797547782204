@import '../../../styles/globalVars.scss';

.select-book-slider .slick-center .select-book-slider__item {
  transform: scale(1.29);
}

.select-book-slider .slick-center .select-book-slider__image {
  border: 1px solid $colorTextAccent;
  border-radius: 10px;
  padding: 8px;
}

.select-book-slider__item {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 120px !important;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.select-book-slider__item img {
  width: 87px;
  height: 135px;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
  border-radius: 5px;
}

.select-book-slider__noimage {
  background-color: $colorBgLight;

  width: 87px;
  height: 135px;
  color: $colorTextLight;
  @include SmallBold;
  font-size: 11px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  border-radius: 5px;

  > label {
    margin-top: 50px;
  }
}


