@import '../../../../styles/globalVars.scss';


.tips {
  padding: $paddingThin 0;

}

.icon {
  float: left;
  margin-right: 8px;
  margin-left: -2px;
  width: 24px;

}

.title {
  @include RegularBold;
  padding-left: 30px;
  padding-bottom: $paddingThin;
}

.text {
  @include Regular;
  padding-left: 30px;
  padding-bottom: $paddingRegular;
}

.form {
  display: flex;

  > div {
    flex: 50%;
  }

  .input {
    margin-left: $paddingSmall;
  }


}