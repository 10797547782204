@import '../../styles/globalVars.scss';

.wrapper {
  padding: 20px 24px 0;
  margin-bottom: -80px;

  .header {
    .BTN_Back {
      width: 32px;
      height: 32px;
      border-radius: 10px;
      margin-left: 6px;
      background: $colorBgPrimary;
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        transform: rotate(90deg);
        width: 22px;

        & > path {
          fill: #fff;
        }
      }
    }

    .title {
      @include H1;
      display: block;
      margin: 20px 2px 30px;
    }
  }

  .form {
    .seletcTeam {
      height: 80px;
      padding: 20px 21px;
      background: #fef4f4;
      border-radius: $radiusSmall;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      text-align: start;

      .subtitle {
        @include RegularBold;
      }

      .subtitleChooseTeam {
        @include RegularBold;
        display: inline-block;
        margin-top: 11px;
      }

      .textTeamName {
        @include Small;
        margin-top: 9px;
        display: inline-block;
      }

      & > svg {
        position: absolute;
        right: 23px;
        top: 32px;
        fill: #202c54;
      }
    }

    & > .body {
      & > div {
        margin-bottom: 35px;
      }

      & > div:nth-child(4) {
        margin-bottom: 12px;
      }
    }
  }
}

.text {
  @include Small;
}

.img {
  background: #ceecfe;
  border-radius: 20px;
}

.delete {
  @include H2;
  color: $colorTextAccent;
  margin: 64px 0 39px;
  position: relative;
}

.listButton {
  height: 108px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 34px;
}

.delete::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -27px;
  height: 1px;
  background-color: #b3b8c2;
}

.wrapperAvatar {
  display: flex;
  align-items: center;

  .img {
    width: 58px;
    height: 58px;
    background: #ceecfe;
    border-radius: 20px;
    margin: 30px 20px 30px 0;
  }

  .title {
    @include RegularBold;
  }
}

.closedForm {
  display: none;
}

.closedAvatar {
  display: none;
}

.modalAvatar {
  display: none;
}

.titleModal {
  @include H1;
  margin-bottom: 37px;
  text-align: center;
}

.wrapperLoaderData {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    max-width: $desktopContentWidth;
    margin: 0 auto;
  }
  .closedForm {
    display: block;
  }

  .modalAvatar {
    display: block;
  }

  .closedAvatar {
    display: none;
  }
}
