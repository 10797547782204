@import '../../styles/globalVars.scss';

.wrapperReadingRecords {
  background-color: $colorBgContent;
  padding-bottom: 80px;
}

:export {
  chartColor: $colorBgPrimary;
}

.noRecords {
  padding: $paddingRegular;
  //color: $colorTextLight;
  //text-align: center;
  align-items: center;
  border-radius: $radiusSmall;
  margin-top: $paddingRegular;
  //margin: $paddingSmall $paddingRegular 0;
  background-color: $colorBgRainbow0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  button {
    width: 130px;
  }
}

.readingRecords {
  padding: 0 $paddingRegular $paddingRegular;
}

.readingRecords::-webkit-scrollbar {
  width: 0;
}

.readingRecords {
  -ms-overflow-style: none;
}

.readingRecords {
  overflow: -moz-scrollbars-none;
}

.showMore{
  padding: $paddingRegular;
  padding-bottom: $paddingLarge;
}

.readingRecordsTitle {
  @include H2;
  color: $colorText;
  display: block;
  margin-left: 20px;
}

.customXAxisTick {
  font-weight: 600;
  font-size: 10px;
  color: $colorText;
}
