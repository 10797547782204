@import '../../styles/globalVars.scss';

.wrapperDonation {
  border-radius: $radiusSmall;
  margin: $paddingSmall $paddingRegular 0;
  padding: $paddingRegular;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: calc(100% - 40px);
}

.donationBackground0 {
  background-color: $colorBgRainbow0;
}

.donationBackground1 {
  background-color: $colorBgRainbow1;
}

.donationBackground2 {
  background-color: $colorBgRainbow3;
}

.donation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: calc(100% - 40px);
}

.donorName {
  @include AccentBold;
  color: $colorText;
}

.donorMessage {
  @include Regular;
  color: $colorText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  text-align: start;
}

.donorAmount {
  @include H2;
  color: $colorTextPrimaryDark;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dateDonation {
  color: $colorText;
  margin-bottom: 27px;
}

.donorAmountModal {
  font-weight: 600;
  font-size: 36px;
  color: $colorTextPrimary;
  margin-bottom: 27px;
  box-sizing: border-box;
}

.donorMessageModal {
  @include Accent;
  color: $colorText;
  margin-bottom: 44px;
  text-align: center;
}

@media screen and (min-width: $desktopMinWidth) {
  .donorMessageModal {
    margin-bottom: 24px;
  }
}
