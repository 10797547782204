@import '../../../../styles/globalVars.scss';


.AmountSelector {
  padding: $paddingRegular;
  padding-top: $paddingLarge;
}

.selector {
  justify-content: center;
  display: flex;
  align-items: center;
}

.value {
  @include H1;
  font-size: 28px;
  margin: 0 20px;
  min-width: 75px;
  text-align: center;
}

.decBtn, .incBtn {
  background-color: $colorBgPrimary;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  width: 18px;
  font-size: 18px;
  height: 18px;
  padding: 4px 5px 6px 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover, &:active{
    background-color: lighten($_colorBgPrimary, 10);
    transition: 0.3s ease;
    box-shadow: 0 0 7px transparentize($colorBgPrimaryLight, 0.5);
  }
}

.decBtn {
  padding-top: 3px;
  padding-bottom: 7px;
}

@media screen and (min-width: $desktopMinWidth) {
  .value {
    font-size: 32px;
  }
}
