@import '../../styles/globalVars.scss';

.modalOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
  transition: all 0.5s ease;
  overflow: hidden;
}

.modalWindow {
  z-index: 10;
  min-height: 100px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: $radiusModals $radiusModals 0 0;
  padding: $paddingLarge $paddingRegular;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: $colorBgModalContent;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  min-height: 400px;
  //margin-bottom: 0;
}

.fadein .modalWindow {
  transform: translateY(0%);
}

.fadein.modalOverlay {
  pointer-events: all;
}

.modalTitle {
  @include H2;
  text-align: center;
  color: $colorText;
  margin-bottom: 4px;
}

.fadein {
  background-color: $colorBgModalDarkness;
}

// .fadeOut {
//background-color: $backgroundColorModalOverlayIn;
// }

@media screen and (min-width: $desktopMinWidth) {
  .modalWindow {
    // top: 0;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    border-radius: $radiusModals;
  }

  .fadein .modalWindow {
    transform: translateY(-25vh);
  }
}
