@import '../../../styles/globalVars.scss';


.Beneficiary {

  text-align: center;

  .cover{
    margin-top: 15px;
    width: 100%;
    border-radius: 15px;
    //padding: 20px 0 10px 0;
  }
  .avatarBg {
    margin-top: 30px;
    min-width: 350px;
    height: 100px;
    background-image: url("../../../assets/images/illustrations/bg_donation_page_avatar.svg");
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      background-color: $colorBgAvatar;
      border-radius: 100px;
    }
  }

  .name {
    @include H2;
    margin-bottom: $paddingThin;
  }

  .caption{
    margin-bottom: $paddingSmall;
  }

}

@media screen and (min-width: $desktopMinWidth) {
  .Beneficiary {

    .cover {
      width: 250px;
      //padding: 20px 0 10px 0;
    }
  }
}
