@import '../../../styles/globalVars.scss';


.FundraiserInfo {
  margin-top: $paddingLarge;
  background-color: #fff;
  border-radius: $radiusModals $radiusModals 0 0;
  padding: $paddingRegular;
  min-height: 400px;
  padding-bottom: $paddingLarge;


  .photoRight{
    width: 100%;
    border-radius: $radiusSmall;
    margin-bottom: $paddingSmall;
  }

  .media{
    iframe{
      width: 100%;
      border-radius: $radiusSmall;
      min-height: 130px;
    }

    .photo {
      width: 100%;
      border-radius: $radiusSmall;
      margin-bottom: $paddingSmall;
    }

    margin-bottom: $paddingRegular;

  }

  h3 {
    margin-bottom: $paddingSmall;
    padding-top: $paddingSmall;
  }

  .summary {
    @include Regular;
    line-height: 155%;
    white-space: pre-wrap;
    //padding-top: $paddingRegular;

    a{
      text-decoration: underline;
    }
  }

  ul {
    @include Regular;
    line-height: 155%;
  }

  .footer {

    text-align: center;
    h3, a, span, p {
      color: $colorTextLight;
      text-align: center;
    }

    p{
      margin: 0 auto;
      max-width: 400px;
    }
    a {
      text-decoration: underline;
    }

    .small{
      @include Small;
    }
  }

  .divider {

    background-color: $colorDivider;
    height: 1px;
    border-radius: 5px;
    margin: $paddingRegular auto;
  }

  .dividerSmall{
    background-color: $colorDivider;
    width: 100px;
    height: 5px;
    border-radius: 5px;
    margin: $paddingRegular auto;
  }

  .showMore {
    cursor: pointer;
    @include RegularBold;
    color: $colorTextPrimary;
    padding: $paddingRegular 0;

  }

  opacity: 0;

  &.hidden {
    //margin-top: 200px;
    opacity: 0;
    transition: .4s ease;
  }

  &.visible {
    //margin-top: $paddingLarge;
    opacity: 1;
    transition: .4s ease;
  }




}

@media screen and (min-width: $desktopMinWidth) {
  .FundraiserInfo {
    margin-top: $paddingLarge;
    padding: $paddingRegular $paddingRegular*2;


    .photoRight{
      width: 270px;
      float: right;
      border-radius: $radiusSmall;
      margin-left: $paddingRegular;
      margin-bottom: $paddingSmall;
    }

    .media{
      width: 100%;
      display: flex;

      .photo {
        flex-grow: 1;
        height: 160px;
        width: auto;
      }
      iframe{
        height: 160px;
        flex-grow: 1;
        margin-left: $paddingSmall
      }
    }

  }


}



