@import '../../../styles/globalVars.scss';

.wrapper {
  // &::after {
  //   content: '';
  //   position: static;
  //   width: 100%;
  //   height: 58px;
  //   z-index: 10;
  //   background: linear-gradient(180deg, #fff 29.03%, rgba(255, 255, 255, 0) 100%);
  //   top: -15px;
  // }
  padding-bottom: 60px;

  .listcard {



    &::-webkit-scrollbar {
      width: 0;
    }

    .cardBackground0 {
      background-color: $colorBgRainbow0;
    }

    .cardBackground1 {
      background-color: $colorBgRainbow1;
    }

    .cardBackground2 {
      background-color: $colorBgRainbow3;
    }

    .cardBackground3 {
      background-color: $colorBgRainbow3;
    }

    .cardBackground4 {
      background-color: $colorBgRainbow2;
    }

    .cardBackground5 {
      background-color: $colorBgRainbow1;
    }

    .wrapperCard {
      height: 80px;
      border-radius: $radiusSmall;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 13px 9px 6px;
      width: 100%;


      .listTitle {
        margin-left: 16px;
        text-align: start;
      }

      .avatar {
        background-color: #fff;
        border-radius: $radiusSmall;
        width: 61px;
        height: 61px;
      }

      &.currentTeam {
        border: 1px solid $colorBgPrimary;
        position: relative;
        box-sizing: border-box;

        & > .check {
          background-color: rgba(255, 255, 255, 0);
          position: absolute;
          right: 22px;
          top: 27px;

          & > path {
            fill: $colorBgPrimary;
          }
        }
      }
    }
  }


}

.loggedAs{
  color: $colorTextLight;
  text-align: center;
  padding-top: $paddingRegular;
  @include Small;
}


@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    left: 120px;
    right: 120px;
    bottom: 100px;
    //height: 300px;
    overflow-y: scroll;
  }
}
