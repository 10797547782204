@import '../../styles/globalVars.scss';

.wrapperHeader {
  position: sticky;
  top: -40px;
  left: 0;
  right: 0;
  z-index: 3;
  border-radius: 0 0 $radiusRegular $radiusRegular;
  padding: 1px;
  background-color: $colorBgHeader;
}

.wrapperHeaderProgress {
  position: sticky;
  top: -130px;
  height: 180px;
  z-index: 3;
  background: 100% no-repeat url('./header_bg.svg'), $colorBgHeader;
  background-size: cover;
  background-position: top 2px right 0;
}

.wrapperHeaderSettings {
  display: none;
}

.wrapperHeaderbooks {
  height: 240px;
  position: sticky;
  top: -190px;
  padding: 1px;
  background-color: $colorBgHeader;
  border-radius: 0 0 $radiusRegular $radiusRegular;
  z-index: 3;

  .inputWrapper {
    padding: 0 20px;
    margin: 0 auto;
    margin-top: 15px;
    max-width: $desktopContentWidth;
    display: block;
  }
}

.navDesktop {
  display: none;
  position: sticky;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px 30px;
  align-items: center;
  top: 35px;
  background-color: $colorBgHeader;
  z-index: 6;
  border-radius: 0 0 $radiusRegular $radiusRegular;
}

.mainNav {
  display: flex;
}

.wrapperAvatarsDesktop {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-left: 50px;
  align-items: center;
}

.readDesktop {
  width: 50px;
  height: 50px;
  background-color: $colorBgAvatar;
  padding: 12px;
  border-radius: 25px;
  box-sizing: border-box;
}

.mainNavItem {
  @include AccentBold;
  color: $colorTextWhite;
  padding: 10px 15px;
  border-radius: $radiusModals;
  font-weight: 500;
  cursor: pointer;
  margin: 0 6px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
}

.mainNavItem:hover {
  color: $colorTextWhite;
}

.mainNavItemActive {
  @include AccentBold;
  background-color: darken($colorBgPrimary, 3%);
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.headerLogo {
  display: none;;
  position: absolute;
  left: 35px;
  //margin: 30px;
  //margin-top: 30px;
  top:50px;
  img {
    width: 170px;
  }
}

.avatarDesktop {
  display: none;
  width: 58px;
  height: 58px;
  border-radius: 20px;
  background-color: $colorBgAvatar;
  position: absolute;
  right: 35px;
  top: 50px;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  padding: 52px $paddingRegular 15px;
  position: relative;
  z-index: 5;
  max-width: $desktopContentWidth;
  margin: 0 auto;
}

.contentHeaderProgress {
  padding-top: 52px;
  padding-bottom: 0;
}

.title {
  @include Accent;
  color: $colorTextWhite;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subtitle {
  @include H1;
  color: $colorTextWhite;
  line-height: 100%;
}

.avatarMobile {
  width: 58px;
  height: 58px;
  border-radius: 20px;
  background-color: $colorBgAvatar;
}

.avatarMobileProgress {
  width: 47px;
  height: 47px;
}

.progressTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;
  padding: 35px 30px 0;
}

.booksTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  padding: 34px 30px 0;
}

.navItemProgress {
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 20px;
  user-select: none;

  transition: 0.7s ease;

  &:active {
    background-color: $colorBgPrimaryLight;
    transition: 0.5s ease;
  }
}

.navItemProgressActive::before {
  content: '';
  position: absolute;
  background-color: $colorBgAvatar;
  box-shadow: 4px 4px 30px rgba(129, 186, 219, 0.2);
  border-radius: $radiusRegular $radiusRegular 0px 0px;
  width: 22px;
  height: 2px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
}

.navTextProgress {
  @include RegularBold;
  color: $colorTextWhite;
  opacity: 0.6;
  position: relative;
  padding: 6px 11px;
  display: block;
}

.navTextProgressActive {
  opacity: 1;
}

.wrapperHeaderSettings {
  display: none;
}

@media screen and (min-width: $desktopMinWidth+400px) {
  .headerLogo {
    display: block;
  }
}
@media screen and (min-width: $desktopMinWidth) {

  .avatarMobile {
    display: none;
  }

  .avatarDesktop {
    display: block;
  }

  .progressTabs {
    padding: 60px 30px 0;
  }

  .wrapperHeaderbooks {

    .inputWrapper {
      padding: 0 20px;
      margin-top: 42px;
    }
  }


  //.wrapperHeaderbooks {
  //  .inputWrapper {
  //    margin: 0 calc(50% - 240px);
  //  }
  //
  //  .navProgress {
  //    margin: 7px calc(50% - 220px);
  //  }
  //}
  //
  //.title {
  //  display: none;
  //}
  //.wrapperHeaderSettings {
  //  display: block;
  //}
  //
  //.wrapperHeaderProgress {
  //  background-position: bottom 112px right 0;
  //}
  //
  //.wrapperHeader {
  //  height: 220px;
  //  top: -90px;
  //}
  //
  //.navDesktop {
  //  display: flex;
  //}
  //
  //.navDesktop::before {
  //  content: '';
  //  position: absolute;
  //  background-color: $colorBgHeader;
  //  width: 100%;
  //  height: 100px;
  //  bottom: 97px;
  //  left: 0;
  //  right: 0;
  //}
  //
  //.contentHeader {
  //  display: flex;
  //  justify-content: space-between;
  //  z-index: 5;
  //  margin-left: calc(50% - 335px);
  //  padding: 0;
  //  margin-top: 35px;
  //}
  //
  //.avatarMobile {
  //  display: none;
  //}
  //
  //.navProgress {
  //  margin: 48px calc(50% - 220px);
  //  padding: 0;
  //}
  //
  //.navItemProgressActive::before {
  //  top: 41px;
  //}
  //
  //.showNav {
  //  display: none !important;
  //}
  //
  //.wrapperHeaderSettings {
  //  display: block;
  //  height: 200px;
  //  top: -60px;
  //}
}
