@import '../../../styles/globalVars.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 42px 0 60px;
  position: relative;
}

.listCard {
  margin-top: 30px;
}

.wrapper::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -34px;
  background-color: $colorDivider;
}

.title {
  @include H1;
  color: $colorText;
  margin-bottom: 5px;
}

.text {
  @include Regular;
  color: $colorText;
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  z-index: 2;
  width: 100%;

  //&:hover::before {
  //  content: '';
  //  z-index: -1;
  //  height: 80px;
  //  top: -8px;
  //  left: -20px;
  //  right: -20px;
  //  position: absolute;
  //  background-color: #f8f8f8;
  //}

  //&:active::before {
  //  content: '';
  //  z-index: -1;
  //  height: 80px;
  //  top: -8px;
  //  left: -20px;
  //  right: -20px;
  //  position: absolute;
  //  background-color: #f8f8f8;
  //}

  .editBtn{
    padding: $paddingSmall;
    margin-right: -10px;
    &:hover, &:active {
      background-color: $colorBgLight;
      border-radius: 40px;
      //padding: 30px;
    }
    //  content: '';
    //  z-index: -1;
    //  height: 80px;
    //  top: -8px;
    //  left: -20px;
    //  right: -20px;
    //  position: absolute;
    //  background-color: #f8f8f8;
    //}

  }

  .avatar{
    margin-right: $paddingRegular;
    width: 72px;
  }

  .imgCard {
    background-color: #ceecfe;
    border-radius: 20px;
    width: 64px;
  }

  .imgOk{
    margin-left: -15px;
    margin-bottom: -5px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .nameCard {
    margin-right: auto;
    @include H2;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperButton {
    padding: 0 120px;
  }
  .card {
    margin-bottom: 30px;

    &:hover::before {
      content: '';
      z-index: -1;
      height: 80px;
      top: -10px;
      left: -50px;
      right: -50px;
      position: absolute;
      background-color: #f8f8f8;
    }

    &:active::before {
      content: '';
      z-index: -1;
      height: 80px;
      top: -10px;
      left: -50px;
      right: -50px;
      position: absolute;
      background-color: #f8f8f8;
    }
  }

  .wrapper {
    margin-bottom: 80px;
  }

  .wrapper::after {
    bottom: -40px;
  }
}
