@import '../../../styles/globalVars.scss';

.wraper {
  display: flex;
  flex-direction: column;

  svg {
    margin: 30px auto 35px;
    display: block;

    path {
      fill: $colorBgPrimary;
    }
  }

  svg.delete {
    path {
      fill: #f0503c;
    }
  }
  button.delete {
    background-color: #f0503c;
  }

  .title {
    @include Accent;
    margin: 0 auto 60px;
    display: block;
    width: 250px;
    text-align: center;
    min-height: 50px;
  }

  .listButton {

    display: flex;

    button:first-child {
      margin-right: 20px;
    }
  }
}
