@import '../../../styles/globalVars.scss';

.wrapper {
  min-height: 120px;
  max-height: 170px;
  background: #ecf4f9;
  border-radius: $radiusSmall;
  padding: 8px 15px;

  .textarea {
    @include Accent;
    //resize: none;
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    outline: none;


    &::placeholder {
      color: $colorTextLight;
    }
  }

}

.error {
  color: $colorTextAccent;
  margin: 0 15px !important;
  display: block;
  @include Small;
}
