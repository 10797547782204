@import '../../../../styles/globalVars.scss';

.wrapper {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
  z-index: 5;
  padding: 0 30px 30px;

  .title {
    margin: 85px 0 41px;
    display: inline-block;
  }

  .BTN_Back {
    background-color: $colorBgPrimary;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    display: none;
  }
}
