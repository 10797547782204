@import '../../../styles/globalVars.scss';

.wraper {
  svg {
    margin: 40px auto 35px;
    display: block;

    path {
      fill: $colorTextAccent;
    }
  }

  .title {
    @include H2;
    margin: 0 auto 60px;
    display: block;
    text-align: center;
  }

  .listButton {
    display: flex;

    button:first-child {
      margin-right: 20px;
    }
  }
}
