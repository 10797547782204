@import '../../styles/globalVars.scss';

.wrapperFooterNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: $radiusSmall $radiusSmall 0px 0px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 92px;
    height: 75px;
    background-image: url('./iconNav/subtract.svg');
    background-repeat: no-repeat;
    background-size: 92px;
  }
}

.footerNav {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  width: calc(50% - 41px);
  background-color: #fff;
  box-shadow: 0px -3px 30px -19px rgba(122, 124, 147, 1);
  border-radius: 0px 15px 0px 0px;
}

.left {
  margin-right: 80px;
}

.navItem {
  display: flex;
  flex-direction: column;
  position: relative;
  text-transform: capitalize;
}

.navItemActive::before {
  content: '';
  position: absolute;
  background: $colorTextAccent;
  border-radius: 0px 0px 2px 2px;
  width: 18px;
  height: 2px;
  top: -18px;
  left: 50%;
  transform: translate(-50%, 0);
}

.navImg {
  margin-bottom: 3px;
  height: 22px;
}

.navText {
  @include FooterMenu;
  color: $colorText;
  opacity: 0.6;
}

.achievementModal {
  margin-bottom: -120px;
}

.navTextActive {
  color: $colorTextAccent;
  font-weight: 600;
}

.footerNavLeft {
  position: relative;
  overflow: hidden;
  margin-right: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  width: calc(50% - 41px);
  box-shadow: 0px -3px 30px -19px rgba(122, 124, 147, 1);
  border-radius: $radiusSmall 0px 0px 0px;
  background-color: #fff;
}

.holeIcon {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -41px;
  z-index: 10;
}

//@media screen and (min-width: $desktopMinWidth) {
//  .footerNavWrapper {
//    display: none;
//  }
//  .footerNavLeft {
//    display: none;
//  }
//}

.footerNavWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.leftShadow {
  position: absolute;
  bottom: 50px;
  box-shadow: 0px -1px 20px 0px #7a7c93;
  width: calc(50% - 45px);
  background-color: red;
  height: 15px;
  border-radius: $radiusSmall 0px 30px 0px;
}

.rightShadow {
  position: absolute;
  bottom: 50px;
  right: 0px;
  box-shadow: 0px -1px 20px 0px #7a7c93;
  width: calc(50% - 45px);
  background-color: red;
  height: 15px;
  border-radius: 0 $radiusSmall 0 $radiusRegular;
}

.footerNavWrapperIsOpen {
  .footerNavOverlay {
    opacity: 0.7;
    pointer-events: all;
  }

  .footerNavMiddle {
    z-index: 3;
  }

  .footerNavMenuItem {
    pointer-events: all;
    opacity: 1;
    transform: scale(1);
  }

  .actionsTitle {
    display: block;
  }
}

.footerNavMiddle {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 92px;
  width: 92px;
  height: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.readIconLoad {
  position: absolute;
}

.footerNavMiddleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-top: -23px;
  z-index: 2;
  background-color: $colorBgFooterActionBtn;
  box-shadow: 0 3px 15px rgba(29, 172, 227, 0.4);
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  transition: 0.7s ease;

  &:active {
    background-color: $colorBgPrimaryDark;
    transition: 0.4s ease;
    margin-top: -21px;
  }
}

.closeIcon {
  max-width: 14px;
}

.footerNavOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: $colorText;
  transition: opacity 0.3s ease;
  pointer-events: none;
  opacity: 0;
}

.footerNavMenu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 140px;
}

.actionsTitle {
  @include FooterActionsTitle;
  position: absolute;
  color: $colorTextWhite;
  left: -50%;
  margin-left: -55px;
  right: 0;
  width: 350px;
  bottom: 290px;
  display: none;
}

.footerNavMenuItem {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  transform: scale(0.3);
  opacity: 0;
  transition-duration: 0.3s;
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.footerNavMenuItem:nth-child(1) {
  left: -90px;
  top: -5px;
}

.footerNavMenuItem:nth-child(2) {
  left: 10px;
  top: -90px;
  transition-delay: 50ms;
}

.footerNavMenuItem:nth-child(3) {
  right: -90px;
  top: -5px;
  transition-delay: 0.1s;
}

.footerNavMenuIcon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: $colorBgContent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px -4px 15px rgba(29, 172, 227, 0.31);
}

.footerNavMenuItem:nth-child(2) .footerNavMenuIcon {
  width: 70px;
  height: 70px;
}

.footerNavMenuIcon img {
  max-width: 44px;
}

.footerNavMenuLabel {
  @include RegularBold;
  text-align: center;
  color: $colorTextWhite;
  margin-top: 9px;
}

@media screen and (min-width: $desktopMinWidth) {
  .footerNavLeft {
    justify-content: flex-end;
  }
  .footerNav {
    justify-content: flex-start;;
  }

  .navItem {
    margin: 0 $paddingLarge;
  }
  .modal {
    margin-bottom: -120px;
  }
}
