@import '../../styles/globalVars.scss';

.wrapper {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @include H2;
  text-align: center;
  margin: 0 0 30px 0;
}

.label {
  @include RegularBold;
  text-align: center;
  color: $colorTextPrimary;
  margin: 30px 0;
}

.progressLabel {
  @include Regular;
  text-align: center;
  color: $colorSuccess;
  font-weight: 500;
  margin: 10px 0 20px;
}

.progressImage {
  position: relative;
  height: 260px;
  width: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time {
  display: flex;
  justify-content: center;
}

.timeButton {
  font-size: 16px;
  font-weight: bold;
  background-color: $colorBgPrimary;
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $colorTextWhite;
  border-radius: 50%;
  line-height: 1;
  padding: 0;
  margin-top: 9px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.timeInner {
  margin: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 150px;

  > strong {
    display: block;
    font-size: 48px;
    line-height: 1;
    font-weight: 600;
    margin: 0 3px;
    color: $colorText;
  }
}

.timeBlock {
  text-align: center;
  min-width: 65px;

  strong {
    display: block;
    font-size: 48px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 10px;
    color: $colorText;
  }

  span {
    @include SmallBold;
    display: block;
    color: $colorTextLight;
  }
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
}

.dayItem {
  @include RegularBold;
  border: 1px solid $colorTextLight;
  box-sizing: border-box;
  border-radius: $radiusSmall;
  padding: 10px 15px;
  line-height: 1;
  color: $colorTextLight;
  margin: 0 5px;
  background-color: transparent;
  flex-grow: 1;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:hover {
    color: $colorTextAccent;
  }

  &.isActive {
    color: $colorTextAccent;
    border: 1px solid $colorTextAccent;
  }
}

.choiceDay {
  select {
    display: block;
    width: 100%;
    padding: 10px;
  }
}

.goal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.goalImage {
  width: 62px;
  height: 62px;
  margin-bottom: 15px;
}

.goalLoader {
  text-align: center;
  width: 140px;
  margin-bottom: 10px;
  color: $colorText;
}

.textGoal {
  font-weight: 600;
  color: $colorTextPrimary;
}

.goalTotal {
  color: $colorText;
  font-weight: 600;
  font-size: 27px;
}

.wrapperCard {
  height: 400px;
  display: flex;
  transition: 0.8s ease;
}

.wrapper {
  height: 400px !important;
}

.buttonsSivider{
  height: $paddingSmall;
}

.bookCover{
  display: flex;
  align-items: center;
  justify-items: center;

  img{
    border: 1px solid $colorTextAccent;
    border-radius: 10px;
    padding: 8px;
    margin: 0 auto;

    height: 170px;
    width: 120px !important;
  }
}