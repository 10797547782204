@import '../../../styles/globalVars.scss';

.wraper {

  padding: $paddingRegular 10px;

  .title {
    @include H1;
    display: block;
    text-align: center;
    margin-bottom: 30px;
  }

  .copied{
    background-color: $colorBgPrimary;
    @include Regular;
    text-align: center;
    padding: 18px;
    border-radius: 50px;
    color: #fff;
    transition: transform 0.3s ease;
  }


}
