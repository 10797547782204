@import '../../../styles/globalVars.scss';

.wrapper {
  background-color: $colorBgPrimary;
  position: fixed;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../assets/images/icons/BGWelcomeMob.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &::after {
    content: '';
    background-image: url('../../../assets/images/logo/logo_horizontal_white.svg');
    position: absolute;
    top: 25px;
    left: calc(50% - 78px);
    width: 156px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ChildOnBook {
    position: absolute;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
  }

  .appstore {
    height: 50px;
    text-align: center;
    -webkit-filter: invert(1) contrast(500%);
    margin-top: $paddingRegular;
  }

  .googleplay {
    height: 50px;
    text-align: center;
    -webkit-filter: invert(1) contrast(500%);
    margin-top: $paddingRegular;
  }

  .body {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 54vh;
    left: 50%;
    width: 250px;
    transform: translateX(-50%);
    text-align: center;

    .title {
      font-weight: 600;
      font-size: 30px;
      text-align: center;
      color: $colorTextWhite;
      margin-bottom: 15px;
    }

    .text {
      @include Accent;
      text-align: center;
      color: $colorTextWhite;
    }
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    background-image: url('../../../assets/images/icons/BGWelcomeDesktop.svg');
    background-size: 100% 912px;
    background-position: center 80%;

    .body {
      width: 550px;
      top: 55vh;

      .title {
        font-size: 48px;
      }

      .text {
        font-size: 18px;
        text-align: center;
        color: $colorTextWhite;
      }
    }
  }
}
