@import '../../styles/globalVars.scss';

.progressWrraper {
  background-color: $colorBgLight;
  margin-top: -20px;
  padding-top: 20px;
}

@media screen and (min-width: $desktopMinWidth) {

  .progressContentWrraper {
    margin: 0 auto;
    max-width: $desktopContentWidth;

  }
}
