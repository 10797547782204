@import './styles/globalVars.scss';

body {
  margin: 0;
  font-family: 'Montserrat';
  height: 100%;
  color: $colorText;
  @include Regular;
}

#google_translate_element{
 position: absolute;
  z-index:  100;
  top: 0;
}
textarea {
  @include Accent;
  font-family: 'Montserrat';
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

button {
  border: none;
  outline: none;
  background-color: rgba($color: #000000, $alpha: 0);
  color: $colorText;
  font-family: 'Montserrat';
  display: block;
  align-items: flex-start;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: inherit;
  font-family: 'Montserrat';
  text-align: start;
}

.contentScreen {
  position: relative;
  z-index: 1;
}

.height {
  height: 100%;
}
