@import '../../../styles/globalVars.scss';


.FundraiserStats {

  display: flex;
  justify-content: space-between;
  margin-top: $paddingRegular;
  margin-bottom: $paddingRegular;

  .item {
    text-align: center;

    h2 {
      margin: 0;
      font-weight: 600;
      color: $colorText;
      margin-bottom: 2px;
    }

    label {
      @include Small;

    }
  }


}

@media screen and (min-width: $desktopMinWidth) {
  .FundraiserStats {
    margin: $paddingRegular auto;
    max-width: 400px;
  }
}
