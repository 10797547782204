@import 'src/styles/globalVars';

.achievement {
  margin: 25px 0 0 12px;
  opacity: 0.3;
  cursor: pointer;
}

.achievementCurrent {
  opacity: 1;
}

.achievementImg {
  width: 90px;
  height: 90px;
}

.wrapperAchievementModal {
  height: 380px;
}

.activeBg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;
  transition: 0.5s ease;
  background-image:url('../../../assets/images/achievements/active_cover.svg');
}

.activeBgFull {
  background-size: 62%;
  transition: 0.5s ease;
}

.titleAchievementModal {
  position: relative;
  @include H2;
  color: $colorText;
  display: block;
  margin-bottom: -40px;
  text-align: center;
}

.achievementImgModal {
  position: absolute;
  left: calc(50% - (175px / 2));
  top: 135px;
  width: 175px;
  height: 175px;
}

.achievementTextModal {
  @include RegularBold;
  color: $colorTextLight;
  text-align: center;
  margin-top: -30px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.achievementTextModalCurrent {
  @include RegularBold;
  color: $colorTextPrimary;
  padding: 0 30px;
  position: relative;
}

.partnerLinkBtn{
  margin-top: $paddingSmall;
}

.sponsoredBy{
  color: $colorTextLight;
  text-align: center;
  padding: $paddingSmall;
}
.partnerLogo{
  width: 160px;
  margin: 0 auto;
  margin-bottom: $paddingSmall;
}

.successIcon {
  position: absolute;
  left: 5px;
  width: 16px;
  top: 0;
}

@media screen and (min-width: $desktopMinWidth) {
  .modal {
    margin-bottom: -100px;
  }
}
