@import '../../../styles/globalVars.scss';

.CheckOff {
  svg {
    path {
      fill: #b3b8c2;
    }
  }
}

.CheckOn {
  svg {
    path {
      fill: $colorBgPrimary;
    }
  }
}
