@import '../../styles/globalVars.scss';

.wrapperSettings {
  background-color: #fff;
  margin: 0 20px;
  padding-bottom: 80px;
}

.title {
  @include H1;
  line-height: 1;
  margin: 16px 0 30px;
  display: block;
}

.formEmail {
  display: block;
  margin-top: 30px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapperFormEmail {
  padding: 0 10px;
}

.formPassword {
  display: block;
  margin-top: 30px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperSettings {
    margin: 0 auto;
    max-width: $desktopContentWidth;
    padding: 8px 50px 80px;
    box-shadow: 4px 4px 30px rgba(135, 180, 206, 0.24);
    border-radius: $radiusRegular $radiusRegular 0px 0px;
    box-sizing: border-box;
    margin-top:100px;
  }

  .wrapperSettings::before {
    content: '';
    position: absolute;
    background-color: $colorBgHeader;
    left: 0;
    right: 0;
    top: -100px;
    height: 300px;
    border-radius: 0px 0px $radiusRegular $radiusRegular;
    z-index: -1;
  }
}
