@import '../../styles/globalVars.scss';

// .commingSoon {
//   @include H2;
//   text-align: center;
//   padding-top: 40%;
//   color: $colorTextLight;
// }

.wrapper {
  position: relative;
  padding-bottom: 80px;
}

.wrapperBG::before {
  content: '';
  position: absolute;
  background-color: $colorBgPrimary;
  left: 0;
  right: 0;
  top: -25px;
  height: 50px;
  z-index: -1;
}

.wrapperListBook {
  background-color: $colorBgContent;
  border-radius: $radiusRegular $radiusRegular 0 0;
  min-height: 200px;
  padding: $paddingLarge 24px 0;
  overflow: hidden;

  a:last-child {
    padding-right: 30px;
  }
}

.wrapperBooks {
  border-radius: $radiusRegular $radiusRegular 0 0;
  padding: 28px 0 20px;
  background-color: $colorBgContent;

  .headerBooks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .listBooksText {
    @include RegularBold;
    color: $colorTextPrimary;
    white-space: nowrap;
  }

  .headerBooksTitle {
    @include H2;
    color: $colorText;
  }

  .headerBooksSum {
    margin-left: 3px;
  }

  .listBooks {
    display: flex;
    overflow-y: scroll;
    margin: 0 -25px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    .book {
      margin: 25px 0 0 28px;
    }
  }

  .listBooks::-webkit-scrollbar {
    width: 0;
  }

  .listBooksOpen {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 0;
  }
}

.wrapperLoaderData {
  margin: calc(50vh - 20px) auto;
  width: 40px;
  height: calc(100vh - 70px);
}

.empty {
  padding-top: $paddingLarge;
  text-align: center;

  p {
    color: $colorTextLight;
  }
  img {
    width: 100px;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    margin: 0 auto;
    max-width: $desktopContentWidth;

    &::before {
      display: none;
    }
  }
}
