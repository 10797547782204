@import '../../../styles/globalVars.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 294px);
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.LoaderData {
  position: absolute;
  right: 40px;
  top: 132px;
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: calc(100vh - 400px);
  }
}
