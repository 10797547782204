@import '../../../styles/globalVars.scss';

.wraper {
  padding: $paddingRegular 10px;

  .title {
    @include H1;
    display: block;
    text-align: center;
    margin-bottom: $paddingSmall;

  }

  .caption {
    @include Accent;
    text-align: center;
    margin-bottom:  $paddingLarge;
  }


}
