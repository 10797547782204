@import '../../styles/globalVars.scss';

.wrapperEvents {
  margin: $paddingSmall $paddingRegular 0 $paddingRegular;
  box-shadow: 4px 4px 30px rgba(135, 180, 206, 0.24);
  border-radius: 20px;
  min-height: 204px;
  padding: $paddingLarge $paddingRegular;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  align-items: stretch;
  background-color: $colorBgContent;
}

.background {
  position: absolute;
  background-color: $colorBgPrimary;
  height: 140px;
  top: -40px;
  left: 0;
  right: 0;
  border-radius: 0px 0px 30px 30px;
}

.arrowBtn {
  width: 28px;
  height: 28px;
  margin-right: $paddingRegular;
  position: absolute;
  border-radius: 10px;
  right: -2px;
  margin-top: -8px;
  background: $colorBgLight;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s ease;

  & > svg {
    transition: .4s ease;
    //transform: rotate(90deg);
    width: 22px;

    & > path {
      fill: $colorTextPrimaryDark;
    }
  }

  &.arrowUp {

    background: $colorBgLight;

    & > svg {
      transition: .4s ease;
      transform: rotate(180deg);
      width: 22px;

      & > path {
        fill: $colorTextPrimaryDark;
      }
    }
  }
}

.heading {
  @include Small;
  display: block;
  color: $colorText;
  margin-bottom: 5px;
}

.title {
  @include RegularBold;
  display: block;
  color: $colorText;
  margin: 20px 0 10px;
}

.divider {
  background-color: $colorDivider;
  height: 1px;
  border-radius: 5px;
  margin: $paddingRegular auto;
}

.body {
  @include Regular;
  color: $colorText;
  line-height: 22px;
  white-space: pre-wrap;
  a{
    text-decoration: underline;
    &:hover{
      color: $_colorTextPrimary;
    }
  }
}

.name {
  @include AccentBold;
  color: $colorText;
  margin-top: $paddingThin;
  margin-bottom: $paddingThin;
  line-height: 140%;
  margin-right: 40px;
}

.textEvents {
  @include Small;
  margin-bottom: 25px;
  color: $colorText;
}

.textDateLeft {
  @include SmallBold;
  color: $colorTextAccent;
}

.imgEvent {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $radiusSmall;
  //margin-bottom: 28px;
}

.contentContainer {
  margin-bottom: 18px;
}

.button {
  @include RegularBold;
  color: $colorTextLight;
  padding: $paddingRegular 0;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: -10px;
text-decoration: underline;
  //
  //  svg path {
  //  fill: $colorBgPrimary;
  //}

  &:hover{
    color: $colorTextPrimaryDark;
    .icon {
      > path {
        fill: $colorTextPrimaryDark;
      }
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    margin-bottom: -2px;
    margin-left: $paddingThin;
    > path {
      fill: $colorTextLight;
    }
  }
}


@media screen and (min-width: $desktopMinWidth) {
  .heading, .textEvents {
    @include Regular;
  }
  .name {
    @include H2;
    margin: 4px 0 8px 0;
  }

  .textDateLeft {
    @include Regular;
  }

  .imgEvent {
    //object-fit: cover;
    width: 300px;
    float: right;
    margin-left: $paddingRegular;
    margin-bottom: $paddingSmall;
    margin-top: $paddingRegular;
    padding-right: $paddingSmall;
  }


  .wrapperEvents {
    margin: $paddingSmall 0 $paddingRegular;
  }
}
