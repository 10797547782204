@import '../../../styles/globalVars.scss';
@import '../Books.module.scss';

.recommendation {
  display: flex;
  align-items: center;
  padding-top: $paddingThin;
  padding-bottom: $paddingSmall;
  //overflow: hidden;

  svg {
    min-width: 40px;
    min-height: 40px;
    margin-right: 20px;

    path {
      fill: $colorBgPrimary;
    }
  }

  & > span {
    color: $colorTextPrimaryDark;
  }
}

// .wrapper {
//   background-color: $colorBgContent;
//   border-radius: $radiusRegular $radiusRegular 0 0;
//   height: 400px;
//   padding: 40px 30px 0;
// }
