@import '../../../../styles/globalVars.scss';


.wrapper {
  padding: 0;
  width: 275px;
  margin: 0 auto;
}

.showHide {
  @include RegularBold;
  color: $colorTextPrimary;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 250px;
  user-select: none;

  .visible {
    rotate: 180deg ;
  }
}

.label {
  text-align: right;
  padding: 4px 5px;
}

.value {
  color: $colorTextPrimary;
}

.valueTotal{
  @include RegularBold;
  color: $colorTextPrimary;
}