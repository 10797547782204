@import '../../../styles/globalVars.scss';
@import '../List/List.module.scss';

.wrapper {
  padding: 0 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    margin: 50px 0 34px;
    border-radius: 5px;
    width: 135px;
    height: 220px;
    background-color: $colorBgLight;
  }

  .title {
    @include H2;
    display: inline-block;
  }
}

.bookPrice {
  box-shadow: 1px 2px 5px rgba(135, 180, 206, 0.3);
  border-radius: 15px;
  height: 75px;
  padding: 0 25px;
  margin-bottom: 17px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  .bookPriceWrapper {
    display: flex;
    align-items: center;
    margin-bottom: -3px;

    .price {
      line-height: 170%;
    }

    .priceValue {
      margin-left: 5px;
      @include H2;
      color: $colorTextPrimary;
    }
  }
}

.addition {
  @include Small;
  color: $colorTextLight;
  display: block;
  margin-bottom: 24px;


  a{
    color: $colorTextLight;
    text-decoration: underline;
  }

}

.description {
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 36px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.showDescription {
  max-height: 100%;
  -webkit-line-clamp: unset;
}

.details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @include Small;

  .detailsItem {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .itemTitle {
      @include SmallBold;
      display: block;
      margin-bottom: 5px;
    }

    .itemText {
      color: $colorTextLight;
    }
  }
}

.wrapperError {
  & > .errorText {
    padding: 100px 20px;
    display: block;
  }
}
