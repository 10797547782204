@import '../../../styles/globalVars.scss';

.listButton {
  position: relative;
  margin: auto -30px 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .LogIn {
    width: 50%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;
    -webkit-tap-highlight-color: transparent;

    .text {
      @include RegularBold;
      color: $colorTextWhite;
    }
  }

  .SignUpDisabled {
    opacity: 0.6;
  }

  .SignUp {
    width: 50%;
    height: 70px;
    background: $colorTextPrimary;
    border-radius: $radiusRegular 0px 0px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    .text {
      @include RegularBold;
      color: #fff;
    }
  }

  .stepper {
    width: 50%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
  }

  .block {
    width: 100%;
    height: 60px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .listButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .block {
      display: none;
    }

    .LogIn {
      display: block;
      background-color: #fff;
      box-sizing: border-box;
      width: 170px;
      height: 45px;
      border: 1px solid $colorBgButtonPrimary;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-tap-highlight-color: transparent;
      z-index: 6;

      .text {
        @include RegularBold;
        color: $colorBgButtonPrimary;
      }
    }

    .stepper {
      width: 90px;
      height: 30px;
    }

    .SignUp {
      width: 170px;
      height: 45px;
      background: $colorBgButtonPrimary;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      -webkit-tap-highlight-color: transparent;

      .text {
        color: #fff;
      }
    }
  }
}
