@import '../../../styles/globalVars.scss';

.wrapper {
  padding: 75px 30px 10px;

  .title {
    @include H1;
    margin: 0 0 10px;
    display: block;
  }

  .list {
    margin-top: 41px;
  }
}

.BTN_Back {
  position: absolute;
  z-index: 10;
  width: 32px;
  height: 32px;
  left: 26px;
  top: 23px;
  background: $colorBgPrimary;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  & > svg {
    transform: rotate(90deg);
    width: 30px;
    height: 20px;

    & > path {
      fill: #fff;
    }
  }
}
