@import './palleteDefault';

//========= Color Theme ============

$colorBgContent: $_colorBgContent;
$colorBgLight: $_colorBgLight;
$colorBgPrimary: $_colorBgPrimary;
$colorBgPrimaryDark: darken($_colorBgPrimary, 5);
$colorBgPrimaryLight: lighten($_colorBgPrimary, 5);
$colorDivider: #d3d9e5;

$colorText: $_colorText;
$colorTextWhite: #fff;
$colorTextLight: $_colorTextLight;
$colorTextPrimary: $_colorTextPrimary;
$colorTextPrimaryDark: $_colorTextPrimaryDark;
$colorTextAccent: $_colorTextAccent;
$colorTextAccent2: $_colorTextAccent2;

$colorSuccess: $_colorSuccess;
$colorError: $_colorError;

// ========== Components colors ================

$colorBgHeader: $colorBgPrimary;
$colorBgButtonPrimary: $colorBgPrimary;
$colorBgAvatar: $colorBgContent;
$colorBgModalContent: $colorBgContent;
$colorBgModalDarkness: rgba(32, 44, 84, 0.5);
$colorBgFooterActionBtn: $colorBgPrimary;
$colorBgActiveRow: $colorBgLight;
$colorBgContextMenu: $colorBgPrimary;

$colorBgRainbow0: #f2f8fc;
$colorBgRainbow1: #fef4f4;
$colorBgRainbow2: #f6f6f6;
$colorBgRainbow3: #fffae9;

$colorBgAvatar: $_colorBgLight2;


// ========== Layout ================

$desktopMinWidth: 750px;
$desktopContentWidth: 700px;

$paddingThin: 4px;
$paddingSmall: 12px;
$paddingRegular: 20px;
$paddingLarge: 28px;

$radiusRegular: 30px;
$radiusSmall: 15px;
$radiusModals: 40px;


// ============= Fonts ============

@mixin H1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 142%;
}

@mixin H2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 139%;
}

@mixin Accent {
  font-size: 16px;
  line-height: 150%;
}

@mixin AccentBold {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

@mixin Regular {
  font-size: 14px;
}

@mixin RegularBold {
  font-weight: 600;
  font-size: 14px;
}

@mixin Small {
  font-size: 12px;
  line-height: 123%;
}

@mixin SmallBold {
  font-weight: 600;
  font-size: 12px;
  line-height: 129%;
}

@mixin FooterMenu {
  font-weight: 600;
  font-size: 11px;
}

@mixin FooterActionsTitle {
  font-weight: 600;
  font-size: 22px;
}
