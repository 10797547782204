@import '../../styles/globalVars.scss';

.wrapperAchievement {
  border-radius: $radiusRegular $radiusRegular 0 0;
  padding: 28px 20px 20px;
  background-color: $colorBgContent;
}

.headerAchievement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listAchievement {
  display: flex;
  overflow-y: scroll;
  margin: 0 -20px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.mockItem{
  min-width: 70px;
  min-height: 70px;
  border-radius: 100px;
  background-color: $colorBgLight;
  margin: 18px;
  margin-top: 30px;
}

.listAchievement::-webkit-scrollbar {
  width: 0;
}

.listAchievementOpen {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-right: 0;
}

.showAllBtn {
  @include RegularBold;
  color: $colorTextPrimary;
}

.headerAchievementTitle {
  @include H2;
  color: $colorText;
}

.achievementTextModalCurrent {
  @include RegularBold;
  color: $colorTextPrimary;
}

@media screen and (min-width: $desktopMinWidth) {
  .showAllBtn {
    display: none;
  }
}
