@import '../../styles/globalVars.scss';

.wrapperMenuSorting {
  position: relative;
}

.menuSortingTitle {
  @include RegularBold;
  color: $colorTextPrimary;
}

.menuSortingList {
  top: 30px;
  right: -23px;
  padding: $paddingSmall $paddingRegular;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 150px;
  //height: 140px;
  background-color: $colorBgContextMenu;
  border-radius: $radiusSmall 0 $radiusSmall $radiusSmall;
  position: absolute;
}

.itemSortingList {
  @include RegularBold;
  line-height: 140%;
  color: $colorTextWhite;
}

.menuSortingRow {
  display: flex;
  justify-content: row;
  align-items: center;
  padding-bottom: 6px;

  padding-top: 6px;
}

.menuSortingImg {
  margin-right: 12px;
  margin-bottom: 3px;
}

.menuSortingRowActive {
  opacity: 0.6;
}
