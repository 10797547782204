@import '../../styles/globalVars.scss';

.wrapperListLeaderboard {
  border-radius: $radiusRegular $radiusRegular 0 0;
  padding: $paddingLarge $paddingRegular;
  background-color: $colorBgContent;
  padding-bottom: 20px;
}

.listLeaderboardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.menuSorting {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowOpen {
  margin-left: 11px;
}

.listLeaderboardHeading {
  @include H2;
  font-size: 17px;
  color: $colorText;
}

.empty{
  color: $colorTextLight;
  min-height: 300px;
}
