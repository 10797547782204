@import '../../../styles/globalVars.scss';

.wrapper {
  position: relative;
  height: 100%;
}

.heading {
  @include H1;
  color: $colorText;
  margin-bottom: $paddingSmall;
  margin-top: $paddingLarge;
  display: block;
}

.wrapper::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: $colorDivider;
  left: 0;
  right: 0px;
}

.card {
  //position: relative;
  //z-index: 3;
  display: flex;
  flex-direction: row;
  //margin-bottom: 30px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: $paddingRegular 0 ;

  .wrapperTitle {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    @include AccentBold;
    color: $colorText;
    //margin-bottom: 10px;
    text-align: start;
  }

  .verifyEmail{
    >span{
      color: $colorTextAccent;
    }
  }

  .value{
    margin-top: 8px;
  }
  .arrow {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: #f8f8f8;
  }
  //
  &:active {
    background-color: #f8f8f8;
  }
}



.arrow {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.LogOut {
  margin-top: 60px;
  @include H2;
  color: $colorTextAccent;
  display: block;
}

@media screen and (min-width: $desktopMinWidth) {
  .card {
    &:hover::before {
      content: '';
      z-index: -1;
      height: 80px;
      top: -17px;
      left: -50px;
      right: -50px;
      position: absolute;
      background-color: #f8f8f8;
    }

    &:active::before {
      content: '';
      z-index: -1;
      height: 80px;
      top: -17px;
      left: -50px;
      right: -50px;
      position: absolute;
      background-color: #f8f8f8;
    }
  }
}
