$_colorBgContent: #fff;
$_colorBgLight: #ecf5fa;
$_colorBgLight2: #CEECFE;
$_colorBgPrimary: #00aeef;
$_colorBgPrimaryDark: #1498da;

$_colorText: #202c54;
$_colorTextWhite: #fff;
$_colorTextLight: #a1a5ae;
$_colorTextPrimary: #00aeef;
$_colorTextPrimaryDark: #0070a6;
$_colorTextAccent: #f0503c;
$_colorTextAccent2: #b248e0;
$_colorSuccess: #5bc940;
$_colorError: #f06418;;
