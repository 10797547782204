@import '../../../../styles/globalVars.scss';


.MemberDetails {


  .personalMessage{
    line-height: 22px;

    label{
      color: $colorTextLight;
      font-style: italic;
      padding-top: $paddingSmall;
    }
  }
  .visible {
    opacity: 1;
    height: fit-content;
    transition: .5s ease;
    //display: block;
    overflow: hidden;
  }

  .hidden {
    opacity: 0;
    height: 0;
    //display: none;
    overflow: hidden;
    transition: .5s ease;
  }

  .divider {
    width: 120px;
    background-color: $colorDivider;
    margin-top: $paddingLarge;
    height: 5px;
    border-radius: 5px;
    //margin: $paddingLarge auto;

  }

  h3{
    //margin: 0;
    //margin-bottom: $paddingSmall;
  }

}

.detailsButton {
  color: $colorTextPrimary;
  @include RegularBold;
  cursor: pointer;
  text-align: center;
  //display: flex;
  //justify-items: center;
  align-items: center;
  padding: $paddingSmall;
  margin-bottom: -13px;
  margin-top: 4px;
  padding-left: $paddingLarge;

  .arrow {
    width: 14px;
    height: 14px;
    transition: .5s ease;
    margin-left: $paddingSmall;
    margin-bottom: -2px;
  }

  .arrowInvert{
    transform: rotate(180deg);
    transition: .5s ease;
  }

}


@media screen and (min-width: $desktopMinWidth) {

}
