@import '../../styles/globalVars.scss';

.wrapperPogressMy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 20px 25px 20px;
}

.wrapperLoaderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.centerPadding{
  width: 20px;
}
.imgLoader {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.textLoader {
  @include Small;
  text-align: center;
  color: $colorText;
  width: 120px;
  margin-bottom: 10px;
}

.textGoal {
  font-weight: 600;
  color: $colorTextPrimary;
}

.totalValue {
  @include H2;
  color: $colorText;
}

@media screen and (min-width: $desktopMinWidth) {

}
