@import '../../../styles/globalVars.scss';

.wrapper {
  position: relative;
  background: #ecf4f9;
  border-radius: $radiusSmall;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
}

.select {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-family: 'Montserrat';
  color: $colorText;
  appearance: none;
  position: relative;
  z-index: 2;
  display: block;
  width: calc(100% - 10px);
  @include Accent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  position: absolute;
  right: 15px;
  //top: 7px;
  path {
    fill: $colorText;
  }
}
