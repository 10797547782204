@import '../../../../styles/globalVars.scss';

.wrapper {
  border: 0.5px solid $colorBgPrimary;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  //height: 170px;
  margin: 18px 0;

  .item.clicked:active {
    background-color: $colorBgLight;
    transition: 0.5s ease;
  }

  .item:hover {
    background-color: $colorBgLight;
    transition: 0.5s ease;
  }

  .item {
    padding: 0 19px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid $colorBgPrimary;

    &:last-child {
      border-bottom: none;
    }
    span {
      @include AccentBold;
      color: $colorTextPrimary;
    }

    & > svg {
      & > path {
        fill: $colorBgPrimary;
      }
    }
  }

  .currentItem {
    background-color: $colorBgPrimary;

    span {
      @include AccentBold;
      color: $colorBgContent;
    }

    & > svg {
      & > path {
        fill: $colorBgContent !important;
      }
    }

    &.clicked:active {
      background-color: $colorBgPrimaryLight;
      transition: 0.5s ease;
    }

    &:hover {
      background-color: $colorBgPrimaryLight;
      transition: 0.5s ease;
    }
  }
}
