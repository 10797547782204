@import '../../../styles/globalVars.scss';


.MainBlock {
  background-color: #fff;
  border-radius: $radiusSmall;
  padding: $paddingRegular;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

  .title {
    @include H2;
    padding-top: $paddingSmall;
    padding-bottom: $paddingSmall;
    text-align: center;
  }

  .paymentsDisabled{
    @include Regular;
    color: $colorTextLight;
    text-align: center;
    padding-top: $paddingSmall;
  }

  .daysLeft {
    @include Regular;
    color: $colorTextLight;
    text-align: center;
    margin-top: $paddingThin;

    strong {
      color: $colorTextAccent;
    }
  }

  .button{
    margin-top: $paddingSmall;
  }

  .controls{
    //width: 330px;
    width: 100%;
  }

  &.hidden {
    margin: 0;
    margin-top: $paddingSmall;
    opacity: 0;
    transition: 0.5s ease;
  }

  &.visible {
    margin: $paddingRegular;
    margin-top: $paddingSmall;
    opacity: 1;
    transition: 0.4s ease;
  }

}

@media screen and (min-width: $desktopMinWidth) {


  .MainBlock {
    padding: $paddingLarge;
    margin-top: 0;
    margin-bottom: $paddingLarge*1.5;

    .title {
      @include H1;
      max-width: 500px;
    }

    .controls{
      width: 300px;
    }

    &.hidden {
      margin: 0;
      margin-top: $paddingSmall;
    }

    &.visible {
      margin: $paddingLarge*3;
      margin-top: $paddingSmall;
      margin-bottom: $paddingLarge*1.5;
    }
  }
}
