@import '../../styles/globalVars.scss';


.DonationPage {
  background-color: $colorBgButtonPrimary;
  background-image: url('../../assets/images/illustrations/bg_circles.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  //background-position: center 20px;
  //height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    background-image: url('../../assets/images/logo/logo_horizontal_white.svg');
    position: absolute;
    top: 30px;
    left: calc(50% - 78px);
    width: 160px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .contentContainer {
    width: 100%;
    padding-top: 80px;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
  }

}

.loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

@media screen and (min-width: $desktopMinWidth) {
  .contentContainer {
    min-width: $desktopContentWidth;
    max-width: $desktopContentWidth;
  }
}
