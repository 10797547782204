@import '../../../../styles/globalVars.scss';


.BooksWishlist {
  padding: $paddingRegular;
}

.title {
  @include AccentBold;
  text-align: center;
}

p {
  text-align: center;
  padding: 0 $paddingRegular;
  line-height: 22px;
  margin-bottom: $paddingLarge;
}

.list {
  margin: 0 auto;
  max-width: 320px;
  padding-left: $paddingSmall;
}

@media screen and (min-width: $desktopMinWidth) {

  .list {
    margin: 0 auto;
    max-width: 320px;
  }
}
