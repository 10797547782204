@import '../../../styles/globalVars.scss';

.wraper {
  padding: 30px 12px;
  .title {
    @include H1;
    display: block;
    margin-bottom: 30px;
  }

  .listButton {
    display: flex;
    margin-top: 56px;

    button:first-child {
      margin-right: 20px;
    }
  }
}
