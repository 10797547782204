@import '../../styles/globalVars.scss';

.wrapperLoader {
  width: 140px;
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperLoader {
    width: 160px;
  }
}
