@import '../../../styles/globalVars.scss';


.DonationInfo {

  display: flex;
  padding-top: $paddingSmall;
  padding-bottom: $paddingSmall;
  justify-content: start;


  //border-bottom: 1px solid $colorDivider;
  .donorName{
    @include Accent;
  }
  .donorMessage{
    //color: $colorTextLight;
    //font-style: italic;
  }

  .time{
    color: $colorTextLight;
    font-style: italic;
  }

  .amount{
    @include AccentBold;
    color: $colorTextPrimaryDark;
    min-width: 60px;
  }
}

@media screen and (min-width: $desktopMinWidth) {


}
