@import '../../../styles/globalVars.scss';

.wrapperLeaderboardCard {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover{
    .leaderboardCardName{
      color: $colorTextPrimary;
    }

  }
}

.leaderboardCardId {
  @include RegularBold;
  color: $colorText;
  margin-right: 9px;
  width: 19px;
}

.leaderboardCardImg {
  display: block;
  width: 38px;
  height: 38px;
  margin-right: 20px;
  //background-color: $backgroundColorleaderboardCardImg;
  border-radius: 50%;
}

.leaderboardCardWrapper {
  @include Regular;
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.leaderboardCardGrade {
  @include Small;
  margin-top: 2px;
  color: $colorTextLight;
}

.leaderboardCardName {
  @include Regular;
  color: $colorText;
  margin-bottom: 3px;
}

.leaderboardCard {
  @include Regular;
  color: $colorText;
}

.leaderboardCardSum {
  @include RegularBold;
  text-align: right;
  color: $colorText;
}

.wrapperLeaderboardCardCurrent {
  background-color: $colorBgActiveRow;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px 0;
  padding: 0 20px 0;
}

.leaderboardCardSumdCurrent {
  @include RegularBold;
  color: $colorTextPrimary;
}

.leaderboardCardNamedCurrent {
  @include RegularBold;
  color: $colorTextPrimary;
  margin-right: auto;
  margin-bottom: 3px;
}

.leaderboardCardIdCurrent {
  @include RegularBold;
  color: $colorTextPrimary;
  margin-right: 9px;
  width: 19px;
}

.leaderboardCardGradedCurrent {
  @include Small;
  color: $colorText;
}

.zeroValue {
  color: $colorTextLight;
}
