@import '../../../styles/globalVars.scss';


.DonateTo {
  background-color: $colorBgButtonPrimary;
  background-image: url('../../../assets/images/illustrations/bg_circles.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  //background-position: center 20px;
  //height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .contentContainer {
    width: 100%;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
  }

  .loader {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  .content {
    background-color: #fff;
    //border-radius: $radiusSmall;
    padding: $paddingRegular;
    //margin: $paddingRegular;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    header {
      display: flex;
      width: 100%;
      //justify-content: space-between;
      align-items: center;
      height: auto;

      .title {
        @include H1;
        padding-top: $paddingSmall;
        padding-bottom: $paddingSmall;
        text-align: center;
        align-self: center;
        margin: 0;
      }

      .backButton {
        width: 32px;
        height: 32px;
        margin-right: $paddingRegular;

        border-radius: 10px;
        background: $colorBgPrimary;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          transform: rotate(90deg);
          width: 22px;

          & > path {
            fill: #fff;
          }
        }
      }
    }


  }
}

.checkbox {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: $paddingSmall;
  }

}

.checkboxDetails {
  @include Small;
  color: $colorTextLight;
  margin-left: $paddingLarge+4;
  margin-top: -14px;
}

.form {
  width: 100%;
  padding: $paddingRegular;

  > div {
    margin-bottom: $paddingRegular;
  }

}

.noStripe{
  color: $colorTextAccent;
}

@media screen and (min-width: $desktopMinWidth) {

  .DonateTo {

    &::after {
      content: '';
      background-image: url('../../../assets/images/logo/logo_horizontal_white.svg');
      position: absolute;
      top: 30px;
      left: calc(50% - 78px);
      width: 160px;
      height: 27px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .content {
      border-radius: $radiusSmall;
      padding: $paddingRegular $paddingLarge*2;
      margin: 0 $paddingLarge*2 $paddingLarge*2 $paddingLarge*2;
      min-height: 500px;
    }

    .contentContainer {
      padding-top: 80px;
      min-width: $desktopContentWidth;
      max-width: $desktopContentWidth;
    }

  }


}
