@import '../List/List.module.scss';

.wrapper {
  position: relative;
  padding: 95px 16px 0;

  .title {
    @include RegularBold;
    display: block;
    margin-bottom: 15px;
  }

  .searchResult{
    padding: 0 $paddingSmall;
  }

  .itemSearch {
    height: 20px;
    margin-bottom: 23px;

    button {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 17px;
      height: 20px;
    }
  }
  .close {
    position: absolute;
    right: 25px;
    top: 26px;
    z-index: 10;

    path {
      fill: $colorBgPrimary;
    }
  }

  .barcode {
    position: absolute;
    right: 24px;
    top: 20px;
    z-index: 10;
    zoom: 1.3;
    path {
      fill: $colorBgPrimary;
    }
  }
}

.wrapperSearch {
  position: absolute;
  left: 15px;
  top: 17px;
  right: 15px;
}

.wrapperLoaderData {
  margin: calc(35vh - 20px) auto;
  width: 40px;
  height: calc(100vh - 70px);
}

.barcodeTip{
  @include Accent;
  text-align: center;
  color: $colorTextLight;


  svg{
    zoom: 2;
  }
  path{
    fill: $colorTextLight
  }
}
