@import '../../styles/globalVars.scss';

.wrapper {
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .circle {
    width: 10px;
    height: 10px;
    background-color: #b3b8c2;
    opacity: 0.5;
    border-radius: 50%;
  }

  .currentCircle {
    width: 10px;
    height: 10px;
    background-color: $colorBgPrimary;
    box-shadow: 0px 1px 6px rgba(0, 174, 239, 0.4);
    border-radius: 50%;
  }

  .passed {
    background: #ceecfe;
  }


}



