@import '../../../styles/globalVars.scss';

.wrapper {
  height: 45px;
  background-color: #ecf4f9;
  border-radius: $radiusSmall;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  .search {
    margin: 2px 14px 0 0;
    align-self: center;

    & > svg {
      path {
        fill: #b3b8c2;
      }
    }
  }

  & > svg {
    position: absolute;
    right: 15px;
    top: 11px;
  }
}

.wrapperError {
  border: 1px solid $colorTextAccent;
  box-sizing: border-box;
  border-radius: $radiusSmall;
  background: rgba(240, 80, 60, 0.06);

  & > input,
  & > .input::placeholder {
    color: $colorTextAccent;
  }
}

.hidePass {
  -webkit-text-security: disc;
}

.input {
  @include Accent;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  font-family: 'Montserrat';
  color: $colorText;
  width: 100%;
}

.input::placeholder {
  @include Accent;
  color: $colorTextLight;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.error {
  color: $colorTextAccent;
  margin: 6px 15px;
  display: block;
  @include Small;
}

.icon {
  position: absolute;
  right: 10px;
}
