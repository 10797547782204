@import '../../styles/globalVars.scss';

.wrapper {
  background-color: $colorBgButtonPrimary;
  background-image: url('../../assets/images/icons/BGWelcomeMob.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  //overflow-y: scroll;
  //-ms-overflow-style: none;
  //overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0;
  }

  .wrapperSignUp {
    height: calc(100% + 200px);
  }

  .listLinkDes {
    display: none;
  }

  .wrapperRegister {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .wrapperLoaderDataRegister {
    position: relative;
    margin-bottom: 27px;

    .LoaderDataRegister {
      position: absolute;
      top: 13px;
      right: 14px;
      z-index: 5;

      path {
        fill: $colorBgPrimary !important;
      }
    }
  }

  .wrapperRegister::-webkit-scrollbar {
    width: 0;
  }

  .listInputLogin {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .firstInput {
      margin-bottom: 17px;
    }
  }

  .social {
    border: 1px solid #b3b8c2;
    box-sizing: border-box;
    border-radius: $radiusSmall;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    margin-bottom: 17px;

    & > span {
      margin: 0 auto;
      display: block;
    }
  }

  .socialGoogle {
    margin-bottom: 40px;
  }

  .BTN_Back {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 26px;
    top: 25px;
    background: rgba($color: #fff, $alpha: 0.3);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    & > svg {
      transform: rotate(90deg);
      width: 30px;
      height: 20px;

      & > path {
        fill: #fff;
      }
    }
  }

  .booksIcon {
    width: 300px;
    position: absolute;
    // transform: translateY(-100%);
    left: calc(50% - 150px);
    top: 83px;
  }

  &::after {
    content: '';
    background-image: url('../../assets/images/logo/logo_horizontal_white.svg');
    position: absolute;
    top: 25px;
    left: calc(50% - 78px);
    width: 156px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .body {
    background-color: $colorBgContent;
    border-radius: $radiusModals $radiusModals 0px 0px;
    padding: 30px 30px 0;
    margin-top: auto;
    min-height: calc(100% - 200px);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 5;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .formRegister {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .input {
        margin-bottom: 20px;
        margin-left: -2px;
      }
    }

    .wrapperForgotPassword {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .textForgotPassword {
        margin-top: -15px;
      }

      button {
        margin: auto 0 50px;
      }
    }

    .title {
      @include H1;
      display: block;
      margin-bottom: 30px;
      position: relative;
    }

    .contract {
      display: flex;
      @include Small;
      padding-bottom: 20px;

      button {
        height: 18px;
        margin-right: 9px;

        svg {
          width: 18px;
        }
      }
    }

    .text {
      @include RegularBold;
      margin-bottom: 12px;
      display: block;
    }

    .hint {
      @include Accent;
      color: $colorTextLight;
      margin-top: 24px;
      display: block;
    }

    .info {
      color: $colorText;
      border: 1px solid $_colorTextPrimary;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 15px;
      line-height: 22px;

      .infoIcon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        margin-bottom: 15px;
        float: left;
        ma

        svg {
          path {
            fill: $_colorBgPrimary;
          }
        }
      }
    }

    .marketButtons {
      padding-top: $paddingLarge;
      //display: flex;
      color: $colorText;

      .appstore {
        height: 50px;
        opacity: 0.8;
        margin-right: $paddingSmall;
      }

      .googleplay {
        height: 50px;
        opacity: 0.6;

      }
    }

    .fieldPrivateInformation {
      margin-top: -17px;
      display: block;
    }

    .link {
      color: $colorTextPrimary;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  .confirmedIcon {
    width: 19px;
    height: 19px;
    background-color: #63da45;
    border-radius: 50%;
    padding: 3px;
    box-sizing: border-box;
    position: absolute;
    right: 45px;
    top: 136px;

    & > path {
      fill: #fff;
      width: 6px;
      height: 9px;
    }
  }

  .filler {
    height: calc(100vh - 200px);
  }

  .bodyTeams {
    margin-top: 200px;
    min-height: auto;
  }

  .listField {
    display: flex;
    flex-direction: column;
    margin-top: 35px;

    .field {
      @include Regular;
      margin-bottom: 5px;
    }

    .value {
      @include RegularBold;
      margin-bottom: 20px;
    }
  }

  .listInput {
    & > div {
      margin-bottom: 25px;
    }
  }

  .listLogin {
    margin: 60px 64px 0;
    display: flex;
    justify-content: space-between;

    & > svg {
      width: 40px;
    }
  }

  .wrapperAvatar {
    display: flex;
    align-items: center;
    height: 58px;
    margin: 30px 0 20px;

    .img {
      width: 58px;
      height: 58px;
      background: #ceecfe;
      border-radius: 20px;
      margin: 18px 20px 30px 0;
    }
  }

  .textareaWrapper {
    margin: 22px 0 19px;
  }
}

.loginWithSocial {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .label {
    @include RegularBold;
  }
}

.socialButtons {
  display: flex;
}

.SocialBtn {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  box-shadow: 1px 3px 10px rgba(135, 180, 206, 0.24);
}


.titleModal {
  @include H1;
  margin-bottom: 37px;
  text-align: center;
}

.openAvatar {
  display: none !important;
}

.openAvatarMob {
  display: block !important;
}

.listLinkMob {
  display: block;
}

@media screen and (min-width: $desktopMinWidth) {
  .openAvatar {
    display: block !important;
  }

  .listLinkMob {
    display: none;
  }

  .fieldPrivateInformation {
    display: none !important;
  }

  .wrapper {
    background-image: url('../../assets/images/icons/BGWelcomeDesktop.svg');
    background-size: 100% 800px;
    background-position: right 20px;
    height: auto;;
    min-height: 100vh;
    //min-height: 100%;
    .wrapperSignUp {
      height: 100%;
    }

    .listLinkDes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 250px;
      margin: 40px auto;
    }

    .confirmedIcon {
      right: 140px;
      top: 185px;
    }

    .BTN_Back {
      display: none;
      position: relative;
    }

    .listInput {
      & > div {
        margin-bottom: 30px;
      }
    }

    // .booksIcon {
    //width: 500px;
    //height: 188px;
    //left: calc(50% - 250px);
    //top: 31%;
    // }

    .body {
      margin: 200px calc(50% - 350px) 0;
      border-radius: $radiusRegular;
      margin-bottom: $paddingLarge*2;
      padding: 60px 120px 50px;
      min-height: 500px;

      .wrapperForgotPassword {
        button {
          margin: auto 0 0;
        }
      }

      .title {
        font-weight: 600;
        font-size: 30px;
        display: block;
        margin-bottom: 40px;
      }

      .text {
        @include RegularBold;
        margin-bottom: 12px;
        display: block;
      }

      .hint {
        @include Accent;
        color: $colorTextLight;
        margin-top: 40px;
        display: block;
      }
    }

    .bodyRegister {
      //min-height: auto;
    }
  }


}
