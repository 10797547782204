@import '../../styles/globalVars.scss';

.wrapperMain {
  background-color: $colorBgContent;
  padding-bottom: 120px;
}

.headingMain {
  @include H2;
  display: block;
  margin: 40px 20px 20px;
}

.noDonations {
  padding: $paddingRegular;
  //color: $colorTextLight;
  //text-align: center;
  border-radius: $radiusSmall;
  margin: $paddingSmall $paddingRegular 0;
  background-color: $colorBgRainbow0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  button {
    width: 130px;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperMain {
    margin: 0 auto;
    width: $desktopContentWidth;
  }
}
