@import '../../styles/globalVars.scss';

.ShareButtons {

  display: flex;
  justify-content: center;
  padding: $paddingRegular;

  svg{
    width: 51px;
    height: 51px;
  }
}

.facebook {
  width: 50px;
  height: 50px;
  -webkit-tap-highlight-color: transparent;
  margin-right: $paddingRegular;

  svg {
    width: 100%;
    height: 100%;
  }


}

.copied {
  position: absolute;
  border-radius: 50px;

  background-color: $colorBgPrimary;
  color: #fff;
  text-align: center;
  padding: $paddingRegular $paddingLarge;
  &.visible {
    opacity: 1;
    bottom: auto;;
    z-index: 1;
    transition: .5s ease;
  }
  &.hidden {
    opacity: 0;
    //bottom: -10000px;
    z-index: -1;
    transition: .5s ease;
  }
}

.twitter {
  padding-right: $paddingRegular;
}

.email {
  background-color: #53d5ce;;
  padding: 17px;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  cursor: pointer;
  //margin-right: $paddingRegular;
  //margin-left: $paddingRegular;
  -webkit-tap-highlight-color: transparent;

  svg {
    margin-left: -3px;
    margin-top: -3px;
    width: 24px;
    height: 24px;
  }

}


.copy {
  background-color: $colorBgLight;
  padding: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  cursor: pointer;
  margin-left: $paddingRegular;

  -webkit-tap-highlight-color: transparent;

  &:hover, :active {
    background-color: #ececec;
    border-radius: 50px;
    overflow: hidden;
  }

  svg {
    margin-left: -2px;
    margin-top: -2px;
    width: 22px;
    height: 22px;
    opacity: 0.8;
  }

}

