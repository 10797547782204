@import '../../styles/globalVars.scss';

.wrapperModalContent {
  padding: 14px 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .title {
      @include H1;
    }
  }
}

.listUser {
  max-height: 400px;
  overflow-y: scroll;
}

.listButton {
  display: none;

  button:first-child {
    margin-right: 20px;
  }
}



.avatar {
  width: 58px;
  height: 58px;
  border-radius: 20px;
  background-color: $colorBgAvatar;
}

.userCard {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  width: 99%;

  .userImg {
    margin-right: 20px;
    background-color: #ceecfe;
  }

  .userName {
    @include H2;
    margin-right: auto;
  }

  & > div {
    margin-top: 5px;
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperModalContent {
    padding: 24px 30px 18px;

    .header {
      margin-bottom: 40px;

      path {
        fill: $colorTextLight;
      }
    }
  }

  .modalUserChange {
    width: 563px;
    min-height: 410px;
  }

  .listUser {
    margin-bottom: 56px;
    max-height: 450px;
  }

  .listButton {
    display: flex;
  }
}
