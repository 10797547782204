@import '../../../styles/globalVars.scss';

.listCard {
  margin-top: 30px;
}

.title {
  @include H1;
  color: $colorText;
  margin-bottom: 5px;
}

.text {
  @include Regular;
  color: $colorText;
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  z-index: 2;
  width: 100%;

  .editBtn {
    padding: $paddingSmall;
    margin-right: -10px;
    min-width: 25px;
    min-height: 25px;

    cursor: pointer;

    &:hover, &:active {
      background-color: $colorBgLight;
      border-radius: 40px;
      //padding: 30px;
    }
  }

  .avatar {
    //margin-right: $paddingRegular;
    min-width: 90px;
    cursor: pointer;

    :hover, :active {
      &.imgCard {
        border: 2px solid $colorBgPrimary;
      }
    }
  }

  .imgCard {
    background-color: #ceecfe;
    border-radius: 20px;
    width: 64px;
    border: 2px solid transparent;
  }

  .imgOk {
    margin-left: -15px;
    margin-bottom: -5px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .nameCard {
    margin-right: auto;
    @include H2;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    min-width:150px;
    width:90%;
    :hover, :active {
      &.imgCard {
        border: 2px solid $colorBgPrimary;
      }
    }
  }
}

@media screen and (min-width: $desktopMinWidth) {
  .wrapperButton {
    padding: 0 120px;
  }
  .card {
    margin-bottom: 30px;

  }

  .wrapper {
    margin-bottom: 80px;
  }

  .wrapper::after {
    bottom: -40px;
  }
}
