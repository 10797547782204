@import '../../../styles/globalVars.scss';

.ReadingRecord {
  position: relative;
  display: flex;
  //justify-content: space-between;
  margin: 0 $paddingRegular $paddingRegular;
}


.ReadingRecordDate {
  @include RegularBold;
  color: $colorText;
  width: 150px;
}
.ReadingRecordTime {
  color: $colorText;
}

.ReadingRecordBookImage {
  width: 30px;
  height: 45px;
  border-radius: 3px;
  margin-right: $paddingRegular;
  background-color: $colorBgLight;
}

.ReadingRecordLine {
  padding: 5px;
  right: 2px;
  position: absolute;
  height: 23px;

  path {
    fill: $colorBgPrimary;
  }
}

.bookTitle {
  @include Small;
  font-weight: normal;
}
.ReadingRecordLine::before {
  content: '';
  right: 2px;
  top: 12px;
  position: absolute;
  width: 18px;
  height: 1.7px;
  background-color: $colorBgPrimary;
}
