@import '../../../styles/globalVars.scss';


.wrapper {

  width: 100%;
  padding-bottom: $paddingThin;
  //padding-top: $paddingThin;

  .values{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    @include RegularBold;
    color: $colorTextLight;

    .amount{
      color: $colorTextPrimary;
      font-size: 24px;
    }

    .goal{
      font-size: 24px;
      margin-bottom: 2px;
      font-weight: normal;
    }

    .caption{
      margin-top: 5px;
      //@include Small;
    }
  }
  .progress {
    border-radius: 10px;
    height: 9px;
    width: 100%;
    background-color: #e5e5e5;

    div {
      border-radius: 10px;
      height: 9px;
      background-color: $colorBgPrimary;
      transition: 1s ease;
    }

  }
}
