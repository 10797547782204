@import '../../../styles/globalVars.scss';

.buttonContent {
  width: 100%;
  background-color: $colorBgButtonPrimary;
  border-radius: 12px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  transition: 0.5s ease;

  &:active, &:hover {
    background-color: lighten($_colorBgPrimary, 10);
    transition: 0.3s ease;
    box-shadow: 0 0 7px transparentize($colorBgPrimaryLight, 0.5);
  }
}

.buttonContent.thin {
  border: 1px solid $colorBgButtonPrimary;
  background-color: transparent;
  box-sizing: border-box;
  height: 45px;
}

.buttonContent.link {
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  height: 45px;
}

.isLoading {
  //position: absolute;
  //left: 10px;
  //top: 4px;
}

.check {
  //position: absolute;
  //left: 16px;
  //top: 11px;
}

.search {
  position: absolute;
  left: 16px;
  top: 11px;
}

.isSuccessful {
  background: $colorSuccess;
}

.buttonText {
  display: block;
  width: 100%;
  border-radius: 12px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContent {
  @include RegularBold;
  color: $colorTextWhite;
}

.textText {
  color: $colorTextPrimary;
  @include RegularBold;
}

.textContent.thin, .textContent.link {
  color: $colorBgButtonPrimary;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
