@import '../../../styles/globalVars.scss';


.DonateSuccess {
  background-color: $colorBgButtonPrimary;
  background-image: url('../../../assets/images/illustrations/bg_circles.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  //background-position: center 20px;
  //height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    background-image: url('../../../assets/images/logo/logo_horizontal_white.svg');
    position: absolute;
    top: 30px;
    left: calc(50% - 78px);
    width: 160px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .contentContainer {

    //min-width: $desktopContentWidth;
    //max-width: $desktopContentWidth;
    padding: $paddingRegular;
    padding-top: 80px;
  }

  .content {
    background-color: #fff;
    border-radius: $radiusSmall;

    //padding: $paddingRegular;
    display: flex;
    flex-direction: column;
    align-items: center;
    //min-height: 100vh;
    padding-top: $paddingLarge;
    padding-bottom: $paddingLarge;
  }

  h3 {
    text-align: center;
    width: 100%;
  }

  .divider {
    width: 120px;
    background-color: $colorDivider;
    height: 5px;
    border-radius: 5px;
    margin: $paddingLarge auto;

  }

  .shareTitle {
    @include H2;
    color: $colorTextLight;
    text-align: center;
    margin: $paddingSmall;
  }

  .shareMessage {
    text-align: center;
    padding: 0 $paddingRegular;
    line-height: 22px;
    color: $colorTextLight;
  }

  .caption {
    text-align: center;
    padding: 0 $paddingRegular;
    line-height: 22px;

    strong {
      color: $colorTextPrimary;
    }

    a {
      //color: $colorTextPrimary;
      text-decoration: underline;
    }
  }

  .loader {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}


@media screen and (min-width: $desktopMinWidth) {

  .DonateSuccess {

    .content {
      border-radius: $radiusSmall;
      padding: $paddingRegular $paddingLarge*2;
      margin: 0 $paddingLarge*2 $paddingLarge*2 $paddingLarge*2;
      min-height: 500px;
      padding-top: $paddingLarge;

      h2 {
        @include H1;
      }
    }

    .contentContainer {
      padding-top: 80px;
      min-width: $desktopContentWidth;
      max-width: $desktopContentWidth;
    }

  }
}
