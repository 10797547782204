@import '../../../styles/globalVars.scss';

.wrapper {
  display: flex;
  margin: 0 -30px 20px;
  position: relative;
  padding: 0 30px;
  // padding-right: 25px;


  .image {
    width: 86px;
    min-width: 86px;
    height: 130px;
    border-radius: 4px;
    margin-right: 20px;
    background-color: $colorBgLight;
    //background-image: url("../../../assets/images/illustrations/NoBookCover.jpeg");
    //background-size: 100%;
  }

  .listText {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .author {
    @include Small;
    margin-bottom: 5px;
    display: block;
  }

  .title {
    @include RegularBold;
    margin-bottom: 5px;
    display: block;
  }

  .pageCount {
    @include Regular;
    color: $colorTextLight;
  }

  .buyButton{
    //background-color: $colorBgPrimary;
    color: $colorTextLight;
    border-radius: 30px;
    border: 1px solid $colorDivider;
    padding: 5px 14px;
    cursor: pointer;
    width: 30px;
    font-weight: 600;
    margin-top: 3px;
  }

  &:hover, &:active{
    .buyButton{
      background-color: $colorBgPrimary;
      border: 1px solid $colorBgPrimary;
      color: #fff;
      transition: .4s ease;
    }
  }

  .menu {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    path {
      fill: $colorTextLight;
    }
  }
}
